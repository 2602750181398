import * as React from "react"
import Layout from "../components/layout"


// markup
const KampPage = () => {

  return (
    <Layout pageTitle={'Kamp'}>
        <div class="py-4 mx-auto">
            <div class="text-2xl font-bold">Kom tegen eind april terug om meer informatie te vinden over het zomerkamp</div>
         </div>
    </Layout>
  )
}

export default KampPage
